import { forwardRef } from 'react';
import Box, { BoxProps } from '../Box/Box';

const LayoutGrid = forwardRef<HTMLDivElement, Omit<BoxProps, 'display'>>(
  (props, forwardedRef) => <Box display="grid" {...props} ref={forwardedRef} />,
);

LayoutGrid.displayName = 'LayoutGrid';

export default LayoutGrid;
