import { StructuredText } from 'datocms-structured-text-utils';
import { CollapsibleSectionContainerFragment } from '@codegen/cmsUtils';
import StructuredTextWrapper from '@ui/components/Content/StructuredTextWrapper';
import Accordion from '@ui-v2/components/Accordion/Accordion';
import Box from '@ui-v2/core/Box/Box';
import Text from '@ui-v2/core/Text/Text';
import { createStructredDataFromCollapsableSection } from './utils/frontPageUtils';

interface Props {
  data?: CollapsibleSectionContainerFragment;
  isTile: Maybe<boolean>;
}

const CollapsibleSectionContainer = ({ data, isTile }: Props) => {
  if (!data) {
    return null;
  }
  const { collapsibleSection } = data;

  return (
    <div>
      <script
        dangerouslySetInnerHTML={{
          __html: createStructredDataFromCollapsableSection(collapsibleSection),
        }}
        type="application/ld+json"
      />

      <Box>
        <Accordion
          iconSize="large"
          items={collapsibleSection.map((items) => ({
            content: (
              <>
                {items.faqText.map((item) => {
                  if (item.__typename === 'StructuredTextRecord') {
                    return (
                      <StructuredTextWrapper
                        data={item.content as Maybe<StructuredText>}
                        key={`${item.id}-text`}
                      />
                    );
                  }

                  if (item.value) {
                    return (
                      <Text as="p" key={`${item.id}-text`}>
                        {item.value}
                      </Text>
                    );
                  }

                  return null;
                })}
              </>
            ),
            heading: items.faqHeading.value,
          }))}
          type="multiple"
          variant={isTile ? 'tiles' : 'blocks'}
        />
      </Box>
    </div>
  );
};

export default CollapsibleSectionContainer;
