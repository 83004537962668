import { AlignmentOptions } from '@shared/types/stylesPropTypes';
import Text from '@ui-v2/core/Text/Text';

interface Props {
  alignment?: Maybe<AlignmentOptions>;
  text: string;
}

const ParagraphWrapper = ({ alignment, text }: Props) => {
  return (
    <div>
      <Text as="p" textAlign={alignment ?? undefined}>
        {text}
      </Text>
    </div>
  );
};

export default ParagraphWrapper;
