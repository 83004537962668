import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageFragment } from '@codegen/cmsUtils';
import Image from '@ui-v2/core/Image/Image';
import { createTypography, mqMin } from '@ui-v2/utils/styleUtils';
import { getImageSizes } from '../../utils/getImageSizes';

export interface Props {
  children: React.ReactNode;
  className?: string;
  icon: Maybe<ImageFragment>;
  title: Maybe<string>;
}

const Container = styled.div(({ theme: { spacings, typography } }) => [
  createTypography(typography.body01),
  css`
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;

    ${mqMin[480]} {
      flex-direction: row;
      gap: ${spacings['8']}px;
    }

    ${mqMin[960]} {
      flex-direction: column;
      gap: 0;
    }
  `,
]);

const HeaderContainer = styled.div<{ noIcon?: boolean }>(
  ({ noIcon, theme: { spacings, typography } }) => [
    createTypography(typography.heading03),
    css`
      display: flex;
      height: 120px;
      flex-direction: row;
      gap: ${spacings['16']}px;

      ${mqMin[480]} {
        width: 50%;
      }

      ${mqMin[960]} {
        width: 100%;
      }
    `,
    noIcon &&
      css`
        height: 70px;
      `,
  ],
);

const Title = styled.div<{ noIcon?: boolean }>(
  ({ noIcon, theme: { colours, spacings } }) => [
    css`
      width: 60%;
      color: ${colours.brand.primary};
      font-size: 24px;
      line-height: 1;

      ${mqMin[480]} {
        margin-top: ${spacings['16']}px;
      }
    `,
    noIcon &&
      css`
        width: 100%;
      `,
  ],
);

const ImageContainer = styled.div(css`
  position: relative;
  width: 30%;

  > img {
    width: auto;
    height: auto;
  }

  ${mqMin[480]} {
    width: 40%;
  }
`);

const Content = styled.div<{ noIcon?: boolean }>(
  ({ noIcon, theme: { colours, shape, spacings } }) => [
    css`
      display: flex;
      height: fit-content;
      flex-flow: row wrap;
      font-weight: bold;

      ${mqMin[480]} {
        width: 50%;
        margin-top: ${spacings['16']}px;
      }

      ${mqMin[960]} {
        width: 100%;
      }

      > div,
      > a {
        display: flex;
        align-items: center;
        color: black;
        font-weight: bold;
      }

      > div:not(:last-child)::after,
      > a:not(:last-child)::after {
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: ${shape.cardPrimary}px;
        margin: 0 12px;
        background-color: ${colours.brand.primary};
        content: '';
      }
    `,
    noIcon &&
      css`
        ${mqMin[480]} {
          width: 80%;
        }

        ${mqMin[960]} {
          width: 100%;
        }
      `,
  ],
);

const Card = ({ children, className, icon, title }: Props) => (
  <Container className={className}>
    <HeaderContainer noIcon={!icon}>
      {icon && (
        <ImageContainer>
          <Image
            alt={icon.alt}
            objectFit="contain"
            sizes={getImageSizes({ Default: '200px' })}
            src={icon.url}
          />
        </ImageContainer>
      )}
      {title && <Title noIcon={!icon}>{title}</Title>}
    </HeaderContainer>
    <Content noIcon={!icon}>{children}</Content>
  </Container>
);

export default Card;
