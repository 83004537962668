import { QueryClient } from '@tanstack/react-query';
import {
  FrontPageCoverConfigFragment,
  useGetPartnerFrontPageContentQuery,
  useGetSearchConfigQuery,
  CollapsibleSectionFragment,
} from '@codegen/cmsUtils';
import { Language, Partner } from '@shared/types/enums';
import { createFaqStructuredData } from '../../SEO/seoUtils';

export const prefetchFrontPage = async ({
  locale,
  partner,
  queryClient,
}: {
  locale: Language;
  partner: Partner;
  queryClient: QueryClient;
}) => {
  await queryClient.prefetchQuery(
    useGetPartnerFrontPageContentQuery.getKey({ partner, locale }),
    async () =>
      await useGetPartnerFrontPageContentQuery.fetcher({ partner, locale })(),
    { retry: 2 },
  );

  await queryClient.prefetchQuery(
    useGetSearchConfigQuery.getKey({ partner }),
    async () => await useGetSearchConfigQuery.fetcher({ partner })(),
    { retry: 2 },
  );
};

export const constructFrontPageCustomConfig = ({
  config,
}: {
  config?: Maybe<FrontPageCoverConfigFragment>;
}) => {
  if (config) {
    return {
      ...(config.titleTypography && {
        titleTypography: {
          ...config.titleTypography,
          type: 'h1',
        },
      }),
      titleColor: config.titleColor,
      subtitleColor: config.subtitleColor,
      backgroundTextColor: config.backgroundTextColor,
      textAlign: config.textAlign,
      useFloatingSearchWidget: config.useFloatingSearchWidget || false,
      showTitleOnTablets: config.showTitleOnTablets || false,
      text: config.text,
      image: config.image,
      spaceAbove: config.spaceAbove,
      spaceBelow: config.spaceBelow,
    };
  }

  return {
    titleColor: null,
    subtitleColor: null,
    backgroundTextColor: null,
    titleTypography: null,
    useFloatingSearchWidget: false,
    textAlign: null,
    showTitleOnTablets: false,
    text: null,
    image: null,
    spaceAbove: null,
    spaceBelow: null,
  };
};

export const createStructredDataFromCollapsableSection = (
  items: CollapsibleSectionFragment[],
) =>
  createFaqStructuredData(
    items.map((section) => ({
      question: section.faqHeading.value,
      answer: section.faqText
        .filter((text) => 'value' in text && text.value)
        .map((text) => 'value' in text && text.value)
        .join('. '),
    })),
  );
