import { BoxProps } from '@ui-v2/core/Box/Box';
import { ImageCardVariant } from './ImageCard';

export const buildImageCardVariantProps = (
  variant: ImageCardVariant,
): {
  alignItems: BoxProps['alignItems'];
  flexDirection: BoxProps['flexDirection'];
  textAlign: BoxProps['textAlign'];
} => {
  switch (variant) {
    case 'side-by-side': {
      return {
        alignItems: 'flex-start',
        flexDirection: 'row',
        textAlign: 'initial',
      };
    }

    case 'stacked': {
      return {
        alignItems: 'center',
        flexDirection: 'column',
        textAlign: 'center',
      };
    }
  }
};

export const buildImageCardVariantImageProps = (
  variant: ImageCardVariant,
): {
  height: BoxProps['height'];
  widthCustom: BoxProps['widthCustom'];
} => {
  switch (variant) {
    case 'side-by-side': {
      return {
        height: 65,
        widthCustom: 65,
      };
    }

    case 'stacked': {
      return {
        height: [65, 65, 130],
        widthCustom: [65, 65, 130],
      };
    }
  }
};
