import { ReactNode, useRef, RefObject } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import useCmsTranslation from '@hooks/useCmsTranslation';
import { ReactComponent as TinyDownArrowIcon } from '../../assets/icons/tiny-down-arrow.svg';
import { centerVertically } from '../../styles/base';
import { resetButton } from '../../styles/cssReset';
import useCarouselScroll from './useCarouselScroll';

export const ARROW_WIDTH = 35;

interface Props {
  activeTabRef: RefObject<HTMLButtonElement>;
  children: ReactNode;
  onNext: () => void;
  onPrev: () => void;
}

const SliderContainer = styled.div`
  display: flex;
`;

const ArrowContainer = styled.div([
  centerVertically,
  css`
    cursor: pointer;

    svg {
      width: ${ARROW_WIDTH}px;
      height: ${ARROW_WIDTH}px;
    }
  `,
]);

const PrevArrowContainer = styled(ArrowContainer)`
  padding-left: 0;

  svg {
    transform: rotate(90deg);
  }
`;

const NextArrowContainer = styled(ArrowContainer)`
  padding-right: 0;

  svg {
    transform: rotate(-90deg);
  }
`;

const TabsContainer = styled.div`
  display: flex;
  width: calc(100% - ${ARROW_WIDTH * 2}px);
  padding: 1px 1px 4px; /* For tab shadow */
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

const NoStyleButton = styled.button(resetButton);

const CarouselSlider = ({ activeTabRef, children, onNext, onPrev }: Props) => {
  const { t } = useCmsTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  useCarouselScroll({ containerRef, activeTabRef });

  return (
    <SliderContainer>
      <NoStyleButton
        aria-label={t('Previous', 'Previous')}
        data-cy="carousel-prev-arrow"
        onClick={onPrev}
      >
        <PrevArrowContainer>
          <TinyDownArrowIcon />
        </PrevArrowContainer>
      </NoStyleButton>
      <TabsContainer data-cy="carousel-tabs" ref={containerRef}>
        {children}
      </TabsContainer>
      <NoStyleButton
        aria-label={t('Next', 'Next')}
        data-cy="carousel-forward-arrow"
        onClick={onNext}
      >
        <NextArrowContainer>
          <TinyDownArrowIcon />
        </NextArrowContainer>
      </NoStyleButton>
    </SliderContainer>
  );
};

export default CarouselSlider;
