import { ImageFragment } from '@codegen/cmsUtils';
import Box from '@ui-v2/core/Box/Box';
import Image from '@ui-v2/core/Image/Image';
import Text from '@ui-v2/core/Text/Text';

interface Props {
  alignment?: string | null;
  image: Maybe<ImageFragment>;
  title: string;
}

const TitleWrapper = ({ alignment, image, title }: Props) => {
  return (
    <Box display={image ? 'flex' : 'block'} gap={16}>
      {image && (
        <Image
          alt={image.alt}
          height={60}
          objectFit="contain"
          src={image.url}
          width={60}
        />
      )}
      <Text
        as="h3"
        textAlign={alignment as undefined | 'left' | 'right' | 'center'}
        variant="heading-3"
      >
        {title}
      </Text>
    </Box>
  );
};

export default TitleWrapper;
