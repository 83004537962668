import { DisplayType } from '@shared/types/enums';
import { legacyBreakpoints } from '@ui-v2/theme/layout';

export const getImageSizes = (
  values: Partial<Record<DisplayType, string>> & { Default: string },
) => {
  const breakpointSizes = Object.keys(legacyBreakpoints).map((breakpoint) => {
    const breakpointValue = values[breakpoint as DisplayType];

    if (!breakpointValue) {
      return null;
    }

    return `(max-width: ${
      legacyBreakpoints[breakpoint as DisplayType] - 1
    }px) ${breakpointValue}`;
  });

  const sizes = [...breakpointSizes, values.Default].filter(Boolean).join(', ');

  if (!sizes) {
    return;
  }

  return sizes;
};
