import { CSSProperties } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ColorFragment, ImageCardFragment } from '@codegen/cmsUtils';
import ImageCard from '@ui/components/Card/ImageCard/ImageCard';
import { tileStyles } from '@ui/components/Tile/Tile';
import LayoutGrid from '@ui-v2/core/Grid/LayoutGrid';

interface ExtendedImageCardFragment extends ImageCardFragment {
  imageObjectFit?: CSSProperties['objectFit'];
}

export interface HowDoesItWorkSectionV2Props {
  imageCardVariant?: 'stacked' | 'side-by-side';
  imageCards: ExtendedImageCardFragment[];
  isTile?: Maybe<boolean>;
}

const StyledCardWrapper = styled.div<{
  descriptionColor?: Maybe<ColorFragment>;
  isTile?: Maybe<boolean>;
  titleColor?: Maybe<ColorFragment>;
}>(({ descriptionColor, isTile, theme, titleColor }) => [
  titleColor &&
    css`
      .image-card-title {
        color: ${titleColor.main};
      }
    `,
  descriptionColor &&
    css`
      .image-card-description {
        color: ${descriptionColor.main};
      }
    `,
  isTile &&
    css`
      ${tileStyles(theme)}
      display: flex;
      height: 100%;
    `,
]);

const HowDoesItWorkSectionV2 = ({
  imageCards,
  imageCardVariant = 'side-by-side',
  isTile,
}: HowDoesItWorkSectionV2Props) => {
  return (
    <LayoutGrid
      gap={48}
      gridTemplateColumns={[
        'auto',
        'auto',
        'auto',
        'repeat(2, 1fr)',
        'repeat(2, 1fr)',
        `repeat(${imageCards.length}, 1fr)`,
      ]}
    >
      {imageCards.map((imageCard) => {
        return (
          <StyledCardWrapper
            descriptionColor={imageCard.descriptionColor}
            isTile={isTile}
            key={imageCard.id}
            titleColor={imageCard.titleColor}
          >
            <ImageCard
              description={imageCard.description?.value}
              image={imageCard.image}
              objectFit={imageCard.imageObjectFit}
              title={imageCard.title?.value}
              variant={imageCardVariant}
            />
          </StyledCardWrapper>
        );
      })}
    </LayoutGrid>
  );
};

export default HowDoesItWorkSectionV2;
