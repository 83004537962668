import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { StructuredText as StructuredTextType } from 'datocms-structured-text-utils';
import { SectionFragment } from '@codegen/cmsUtils';
import { AlignmentOptions } from '@shared/types/stylesPropTypes';
import StructuredParagraphWrapper from '@ui/components/Content/StructuredParagraph';
import CardWrapper from '../FrontPage/CardWrapper';
import CollapsibleSectionContainer from '../FrontPage/CollapsibleSectionContainer';
import ConnectionServiceInfo from '../FrontPage/ConnectionServiceInfo';
import HoverCardWrapper from '../FrontPage/HoverCardWrapper';
import HowDoesItWorkContainer from '../FrontPage/HowDoesItWorkContainer';
import ParagraphWrapper from '../FrontPage/ParagraphWrapper';
import TitleWrapper from '../FrontPage/TitleWrapper';
import VendorCarouselWrapper from '../FrontPage/VendorCarouselWrapper/VendorCarouselWrapper';
import VendorDisplayWrapper from '../FrontPage/VendorDisplayWrapper';

interface Props {
  content: SectionFragment[];
  isSectionTile: Maybe<boolean>;
}

const ContentContainer = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacings['24']}px;
  `,
);

const LandingPageSection = ({ content, isSectionTile }: Props) => {
  const components = content.map((item) => {
    switch (item.__typename) {
      case 'CardContainerRecord':
        return (
          <CardWrapper
            cards={item.card}
            gradient={item.gradient}
            key={`${item.id}CardContainer`}
            textAlign={item.textAlign as Maybe<AlignmentOptions>}
            titleBackgroundColor={item.titleBackgroundColor}
            titleBackgroundGradient={item.titleBackgroundGradient}
            variant={item.variant}
          />
        );
      case 'CollapsibleSectionContainerRecord':
        return (
          <CollapsibleSectionContainer
            data={item}
            isTile={item.isTile}
            key={`${item.id}CollapsibleSection`}
          />
        );
      case 'ConnectionServiceRecord':
        return (
          <ConnectionServiceInfo
            data={item}
            isSectionTile={isSectionTile}
            key={`${item.id}ConnectionServiceInfo`}
          />
        );
      case 'HoverCardContainerRecord':
        return (
          <HoverCardWrapper
            cards={item.hoverCard}
            key={`${item.id}HoverCardWrapper`}
          />
        );
      case 'HowDoesItWorkRecord':
        return (
          <HowDoesItWorkContainer
            item={item}
            key={`${item.id}HowDoesItworkContainer`}
          />
        );
      case 'ParagraphRecord':
        return (
          <ParagraphWrapper
            alignment={item.alignment as Maybe<AlignmentOptions>}
            key={`${item.id}Paragraph`}
            text={item.text.value}
          />
        );
      case 'StructuredParagraphRecord':
        return (
          <StructuredParagraphWrapper
            data={item.content?.content as Maybe<StructuredTextType>}
            key={`${item.id}StructuredParagraph`}
          />
        );
      case 'TitleRecord':
        return (
          <TitleWrapper
            alignment={item.alignment}
            image={item.titleImage}
            key={`${item.id}Title`}
            title={item.title.value}
          />
        );
      case 'VendorCarouselRecord':
        return (
          <VendorCarouselWrapper
            key={`${item.id}Carousel`}
            vendors={item.vendorInfo}
          />
        );
      case 'VendorDisplayRecord':
        return (
          <VendorDisplayWrapper
            data={item}
            key={`${item.id}VendorDisplayWrapper`}
          />
        );
      default:
        return <span />;
    }
  });

  return <ContentContainer>{components}</ContentContainer>;
};

export default LandingPageSection;
