import { useRef, useState } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { mqMin } from '@ui-v2/utils/styleUtils';
import CarouselSlider, { ARROW_WIDTH } from './CarouselSlider';
import CarouselTab from './CarouselTab';
import { CarouselOption } from './CarouselTypes';

export interface Props {
  items: CarouselOption[];
}

const CarouselContainer = styled.div(
  ({ theme: { spacings } }) => css`
    width: 100%;
    margin-top: ${spacings['16']}px;

    ${mqMin[768]} {
      margin-top: ${spacings['24']}px;
    }
  `,
);

const CarouselCard = styled.div<{ isActive: boolean }>(
  ({ isActive, theme }) => [
    css`
      overflow: hidden;
      width: calc(100% - ${ARROW_WIDTH * 2}px);
      border: 1px solid rgba(0 0 0 / 10%);
      margin: 0 auto;
      background-color: ${theme.colours.surface.main};
      box-shadow: ${theme.shadows.medium};
    `,
    !isActive &&
      css`
        display: none;
      `,
  ],
);

const Carousel = ({ items }: Props) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const activeRef = useRef<HTMLButtonElement>(null);

  // Moves the active index when an arrow is clicked
  const changeActiveIndex = (num: number) => {
    const highestIndex = items.length - 1;
    let newIndex = activeIndex + num;

    if (newIndex < 0) {
      newIndex = highestIndex;
    } else if (newIndex > highestIndex) {
      newIndex = 0;
    }
    setActiveIndex(newIndex);
  };

  return (
    <CarouselContainer>
      <CarouselSlider
        activeTabRef={activeRef}
        onNext={() => changeActiveIndex(1)}
        onPrev={() => changeActiveIndex(-1)}
      >
        {items.map((item, idx) => (
          <CarouselTab
            isActive={idx === activeIndex}
            key={item.id}
            onClick={() => setActiveIndex(idx)}
            ref={idx === activeIndex ? activeRef : null}
          >
            {item.sliderItem}
          </CarouselTab>
        ))}
      </CarouselSlider>

      {items.map((item, idx) => (
        <CarouselCard isActive={idx === activeIndex} key={item.id}>
          {item.CardItem}
        </CarouselCard>
      ))}
    </CarouselContainer>
  );
};

export default Carousel;
