import Link from 'next/link';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { VendorDisplayFragment } from '@codegen/cmsUtils';
import Box from '@ui-v2/core/Box/Box';
import Image from '@ui-v2/core/Image/Image';
import { mqMin } from '@ui-v2/utils/styleUtils';

interface Props {
  data: VendorDisplayFragment;
}
const StyledImage = styled(Image, {
  shouldForwardProp: (prop) => prop !== 'restrictWidth',
})<{
  restrictWidth?: Maybe<boolean>;
}>(({ restrictWidth }) => [
  restrictWidth &&
    css`
      width: auto;
      max-width: 140px;
      height: 62px;
      ${mqMin[480]} {
        max-width: 130px;
      }
      ${mqMin[960]} {
        max-width: 120px;
      }
    `,
]);

const VendorDisplayWrapper = ({ data }: Props) => {
  const useFullContainerWidth = data.alignment === 'useFullWidth';

  return (
    <div>
      <Box
        border={data.useBorder ? 'default' : 'none'}
        columnGap={[24, 24, 24]}
        css={{
          borderRadius: data.borderRadius ?? 4,
        }}
        display="flex"
        flexWrap="wrap"
        justifyContent={
          useFullContainerWidth ? 'space-between' : 'space-around'
        }
        p={data.useBorder ? 16 : 0}
        rowGap={[24, 24, 24, 24, 32]}
      >
        {data.vendorDisplayInfo.map(({ link, logoHeight, vendor }) => {
          if (!vendor.logo.url) {
            return null;
          }

          if (link) {
            return (
              <Box alignItems="center" display="flex" key={vendor.name}>
                <Link href={link.href} target={link.target ?? undefined}>
                  <StyledImage
                    alt={vendor.iata}
                    height={logoHeight ?? 30}
                    objectFit="contain"
                    restrictWidth={useFullContainerWidth}
                    src={vendor.logo.url}
                    width={140}
                  />
                </Link>
              </Box>
            );
          }

          return (
            <StyledImage
              alt={vendor.iata}
              height={logoHeight ?? 30}
              key={vendor.name}
              objectFit="contain"
              restrictWidth={useFullContainerWidth}
              src={vendor.logo.url}
              width={140}
            />
          );
        })}
      </Box>
    </div>
  );
};

export default VendorDisplayWrapper;
